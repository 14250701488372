<template>
    <div class="bitsea_launchpad_page">
        <div class="bitsea_launchpad_page_01">
            <Menu/>
        </div>

        <img id="bitsea_launchpad_page_bj" src="../../assets/images/bja.png" class="bitsea_bj_img">

        <div id="bitsea_launchpad_page_02" class="bitsea_launchpad_page_02">
            <div id="bitsea_launchpad_page_02_divs" class="bitsea_launchpad_page_02_divs">
                <div class="bitsea_launchpad_page_021" v-if="!isToMint">
                    <img src="../../assets/images/Launchpad.png" class="bitsea_launchpad_page_0211"/>
                    <div class="bitsea_launchpad_page_0212">
                        <div v-if="nftPiciList.length > 0 && piciObj.isLaunchpad" class="bitsea_launchpad_page_02121" v-for="(piciObj, key) in nftPiciList" :key="key" @click="onMint(piciObj, key)">
                            <img src="../../assets/images/Frame14635.png" class="bitsea_launchpad_page_021211_bj">

                            <img :src="piciObj.details.img" class="bitsea_launchpad_page_021211">
                            <div class="bitsea_launchpad_page_021212">
                                <div class="bitsea_launchpad_page_021212_all">
                                    <div class="bitsea_launchpad_page_0212121">
                                        {{ piciObj.details.title }}
                                    </div>
                                    <div class="bitsea_launchpad_page_0212122">
                                        {{ piciObj.details.snapshot }}
                                    </div>
                                </div>

                                <div class="bitsea_launchpad_page_0212123">
                                    <a v-if="piciObj.details.linka !== null" :href="piciObj.details.linka" target="_blank">
                                        <img v-if="piciObj.details.linka !== null" src="../../assets/images/telegram1.png" class="bitsea_launchpad_page_02121231">
                                    </a>
                                    <a v-if="piciObj.details.linkb !== null" :href="piciObj.details.linkb" target="_blank">
                                        <img v-if="piciObj.details.linkb !== null" src="../../assets/images/Icon.png" class="bitsea_launchpad_page_02121232">
                                    </a>
                                    <a v-if="piciObj.details.linkc !== null" :href="piciObj.details.linkc" target="_blank">
                                        <img v-if="piciObj.details.linkc !== null" src="../../assets/images/Vector1.png" class="bitsea_launchpad_page_02121233">
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div v-if="nftPiciList.length <= 0" class="bitsea_launchpad_page_02122">
                            {{ isLaunchpadLoading ? "Loading..." : "Not Data" }}
                        </div>

                        <div v-if="parseInt($store.state.networkIDstring) === 223" class="bitsea_launchpad_page_02122">
                            Not Data
                        </div>
                    </div>
                </div>

                <div class="bitsea_launchpad_page_022" v-if="isToMint">
                    <div class="bitsea_launchpad_page_0221">
                        <el-carousel indicator-position="outside">
                            <el-carousel-item v-for="item in 1" :key="item">
                                <img class="bitsea_launchpad_page_02211" :src="nftPiciObj.details.img">
                            </el-carousel-item>
                        </el-carousel>
                    </div>

                    <div class="bitsea_launchpad_page_0222">
                        <div class="bitsea_launchpad_page_02221">
                            <div class="bitsea_launchpad_page_022211">
                                <div class="bitsea_launchpad_page_0222111">
                                    {{ nftPiciObj.details.title }}
                                </div>
                                <a v-if="nftPiciObj.details.linkc !== null" :href="nftPiciObj.details.linkc" target="_blank">
                                    <img src="../../assets/images/Vector2.png" class="bitsea_launchpad_page_0222112"/>
                                </a>
                            </div>
                            <img src="../../assets/images/Group247.png" class="bitsea_launchpad_page_022212" @click="returnMint()"/>
                        </div>

                        <div :class="isLaunch ? 'bitsea_launchpad_page_02222_pc' : 'bitsea_launchpad_page_02222'">
                            {{ nftPiciObj.details.describe }}
                        </div>

                        <div class="bitsea_launchpad_page_02222_01" @click="isLaunch = !isLaunch">
                            <i v-if="!isLaunch" class="el-icon-arrow-down"></i>
                            <i v-if="isLaunch" class="el-icon-arrow-up"></i>
                        </div>

                        <div class="bitsea_launchpad_page_02223">
                            <div class="bitsea_launchpad_page_022231">
                                <div class="bitsea_launchpad_page_0222311" v-if="nftPiciObj.details.title === 'MonsterLand404'">
                                    Free
                                </div>
                                <div class="bitsea_launchpad_page_0222311" v-if="nftPiciObj.details.title !== 'MonsterLand404'">
                                    {{ setDecimal(nftPiciObj.piciList[isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1)].price / 1e18, 6) }}
                                    {{ (parseInt($store.state.networkIDstring) === 11501 || parseInt($store.state.networkIDstring) === 1502) ? 'BTC' : ''}}
                                    {{ (parseInt($store.state.networkIDstring) === 22776 || parseInt($store.state.networkIDstring) === 212) ? 'MAPO' : ''}}
                                    {{ (parseInt($store.state.networkIDstring) === 223) ? 'BTC' : ''}}
                                </div>
                                <div class="bitsea_launchpad_page_0222312">
                                    Price
                                </div>
                            </div>

                            <img src="../../assets/images/Line8.png"/>

                            <div class="bitsea_launchpad_page_022232">
<!--                                <div class="bitsea_launchpad_page_0222321">-->
<!--                                    {{-->
<!--                                    nftPiciObj.details.title === "MonsterLand404" ? (10000 - mstlBalanceOf) : (nftPiciObj.piciList[isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1)].mintNum)-->
<!--                                    }}-->
<!--                                    ( {{-->
<!--                                    parseInt(isEndOrStartCode === 0 ? mintNftPici: isEndOrStartCode) === 3-->
<!--                                    ?-->
<!--                                    "Remaining"-->
<!--                                    :-->
<!--                                    "Mint"-->
<!--                                    }} ) / {{-->
<!--                                    parseInt(isEndOrStartCode === 0 ? mintNftPici: isEndOrStartCode) === 3-->
<!--                                    ?-->
<!--                                    nftPiciObj.countAllNum-->
<!--                                    :-->
<!--                                    nftPiciObj.piciList[isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1)].countNum-->
<!--                                    }}-->
<!--                                </div>-->
                                <div class="bitsea_launchpad_page_0222321">
                                    {{
                                        nftPiciObj.details.title === "MonsterLand404"
                                        ?
                                        mstlBalanceOf
                                        :
                                        (
                                        (nftPiciObj.piciList.length > 1 && ((nftPiciObj.piciList.length - 1) === (isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1))))
                                        ?
                                        nftPiciObj.piciList[isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1)].mintNum
                                        :
                                        ((nftPiciObj.piciList[isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1)].countNum)
                                        -
                                        (nftPiciObj.piciList[isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1)].mintNum))
                                        )
                                    }}
                                    ( Remaining )
                                    /
                                    {{
                                    (nftPiciObj.details.title !== "MonsterLand404" && nftPiciObj.piciList.length > 1 && ((nftPiciObj.piciList.length - 1) === (isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1))))
                                    ?
                                    getPiCiAllCount(nftPiciObj.piciList)
                                    :
                                    nftPiciObj.piciList[isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1)].countNum
                                    }}
                                </div>
<!--                                <div v-if="nftPiciObj.details.title === 'MonsterLand404'" class="bitsea_launchpad_page_0222322">-->
<!--                                    Minted / Total Supply-->
<!--                                </div>-->
<!--                                <div v-if="nftPiciObj.details.title !== 'MonsterLand404'" class="bitsea_launchpad_page_0222322">-->
<!--                                    Total Supply-->
<!--                                </div>-->
                                <div class="bitsea_launchpad_page_0222322">
                                    Total Supply
                                </div>
                            </div>

                            <img src="../../assets/images/Line8.png"/>

                            <div v-if="nftPiciObj.details.title === 'MonsterLand404'" class="bitsea_launchpad_page_022233">
                                <div class="bitsea_launchpad_page_0222331">
                                    {{
                                    (parseInt(new Date().getTime() / 1000) < parseInt(nftPiciObj.piciList[0].startTime))
                                    ?
                                    (new Date(nftPiciObj.piciList[0].startTime * 1000).toUTCString())
                                    :
                                    (new Date(nftPiciObj.piciList[1].endTime * 1000).toUTCString())
                                    }}
                                </div>
                                <div class="bitsea_launchpad_page_0222332">
                                    {{
                                    (parseInt(new Date().getTime() / 1000) < parseInt(nftPiciObj.piciList[0].startTime))
                                    ?
                                    "Start Time"
                                    :
                                    "End Time (Limit: " + ((parseInt(new Date().getTime() / 1000) >= parseInt(nftPiciObj.piciList[1].startTime)) ? 1 : 1) + " / Address)"
                                    }}
                                </div>
                            </div>
                            <div v-if="nftPiciObj.details.title !== 'MonsterLand404'" class="bitsea_launchpad_page_022233">
                                <div class="bitsea_launchpad_page_0222331">
                                    <!-- {{ formatTimestamp(nftPiciObj.piciList[isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1)].startTime * 1000) }}-->
                                    {{
                                    (parseInt(new Date().getTime() / 1000) < parseInt(nftPiciObj.piciList[0].startTime))
                                    ?
                                    (new Date(nftPiciObj.piciList[0].startTime * 1000).toUTCString())
                                    :
                                    new Date(nftPiciObj.piciList[isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1)].endTime * 1000).toUTCString()
                                    }}
                                </div>
                                <div class="bitsea_launchpad_page_0222332">
                                    {{
                                    (parseInt(new Date().getTime() / 1000) < parseInt(nftPiciObj.piciList[0].startTime))
                                    ?
                                    "Start Time"
                                    :
                                    "End Time"
                                    }}
                                </div>
                            </div>
                        </div>

                        <div v-if="nftPiciObj.details.title === 'MonsterLand404' && mstlBalanceOf === 0" class="bitsea_launchpad_page_02224" style="background: #717171; cursor: not-allowed;">
                            {{
                            (parseInt(new Date().getTime() / 1000) < parseInt(nftPiciObj.piciList[0].startTime))
                            ?
                            "Waiting Start"
                            :
                            (
                            (parseInt(new Date().getTime() / 1000) > parseInt(nftPiciObj.piciList[1].endTime))
                            ?
                            "End"
                            :
                            (
                            (parseInt(new Date().getTime() / 1000) >= parseInt(nftPiciObj.piciList[0].startTime) && parseInt(new Date().getTime() / 1000) <= parseInt(nftPiciObj.piciList[0].endTime))
                            ?
                            "MINT (Only WhiteList)"
                            :
                            "MINT (Public)"
                            )
                            )
                            }}
                        </div>
                        <div v-if="nftPiciObj.details.title === 'MonsterLand404' && mstlBalanceOf > 0" class="bitsea_launchpad_page_02224" @click="toMint(nftPiciObj.address)">
                            {{
                                (parseInt(new Date().getTime() / 1000) < parseInt(nftPiciObj.piciList[0].startTime))
                                ?
                                "Waiting Start"
                                :
                                (
                                    (parseInt(new Date().getTime() / 1000) > parseInt(nftPiciObj.piciList[1].endTime))
                                    ?
                                    "End"
                                    :
                                    (
                                        (parseInt(new Date().getTime() / 1000) >= parseInt(nftPiciObj.piciList[0].startTime) && parseInt(new Date().getTime() / 1000) <= parseInt(nftPiciObj.piciList[0].endTime))
                                        ?
                                        "MINT (Only WhiteList)"
                                        :
                                        "MINT (Public)"
                                    )
                                )
                            }}
                        </div>

                        <div v-if="nftPiciObj.details.title !== 'MonsterLand404' &&
                        (
                            (nftPiciObj.piciList.length > 1 && ((nftPiciObj.piciList.length - 1) === (isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1))))
                            ?
                            nftPiciObj.piciList[isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1)].mintNum
                            :
                            ((nftPiciObj.piciList[isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1)].countNum)
                            -
                            (nftPiciObj.piciList[isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1)].mintNum))
                        ) === 0" class="bitsea_launchpad_page_02224" style="background: #717171; cursor: not-allowed;">
                            {{
                            (parseInt(nftPiciObj.piciList[isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1)].whiteListOpen) === 0 ? "MINT" : "MINT (Only WhiteList)")
                            }}
                        </div>
                        <div v-if="nftPiciObj.details.title !== 'MonsterLand404' &&
                        (
                            (nftPiciObj.piciList.length > 1 && ((nftPiciObj.piciList.length - 1) === (isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1))))
                            ?
                            nftPiciObj.piciList[isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1)].mintNum
                            :
                            ((nftPiciObj.piciList[isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1)].countNum)
                            -
                            (nftPiciObj.piciList[isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1)].mintNum))
                        ) > 0" class="bitsea_launchpad_page_02224" @click="toMint(nftPiciObj.address)">
                            {{
                            (parseInt(nftPiciObj.piciList[isEndOrStartCode === 0 ? nftPiciIndex : (isEndOrStartCode - 1)].whiteListOpen) === 0 ? "MINT" : "MINT (Only WhiteList)")
                            }}
                        </div>

                        <div v-if="nftPiciObj.address.toString().toUpperCase() === '0x5731F83cABB63D099d7D491E159EC59eF6Aa4469'.toUpperCase()" class="bitsea_launchpad_page_02225">
                            Maximum of {{ maxClaimNum }} boxes per session / Can be claimed: {{ userAllClaimNum }}
                        </div>

                        <div v-if="nftPiciObj.details.title === 'MonsterLand404'" class="bitsea_launchpad_page_02225">
                            <a :href="'https://scan-mainnet.bevm.io/address/'+nftPiciObj.address" target="_blank" style="color: white;">
                                Token Contract: {{ nftPiciObj.address }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bitsea_launchpad_page_03">
                <Bottom/>
            </div>
        </div>
    </div>
</template>

<script>
    import Menu from "../../components/Menu";
    import Bottom from "../../components/Bottom";
    import toolUtils from "../../tools/toolUtils";
    import { mixin1 } from "../../tools/mixins";
    import * as metaMaskTools from "../../tools/chain";

    import transfer from "../../tools/transfer";
    import {
        BigNumber,
        BigNumberStr,
        BigNumberMul,
        BigNumberDiv,
        BigNumberAdd,
        BigNumberSub,
    } from '../../tools/index';
    import {
        debug
    } from "../../config";
    import {
        ipfsUrl,
        openUrlSLD,
        bitseaNfts,
        controlAddress,
        shopAddress,
        monsterLandAddress
    } from "../../tools/projectConfig";

    export default {
        name: "Index",
        components: { Menu, Bottom },
        mixins: [ mixin1 ],
        data() {
            return {
                toolUtils,
                // bitseaNfts,
                mstlBalanceOf: 0,
                isLaunchpadLoading: false,
                bitseaPageWidth: null,
                bitseaPageHeight: 0,
                launchpadContainerHeight: 0,
                isToMint: false,
                nftPiciObjIndex: -1,
                nftPiciObj: null,
                nftPiciList: [],
                isEndOrStartCode: 0,
                mintNftPici: 0,
                nftPiciIndex: -1,
                maxClaimNum: 0,
                userAllClaimNum: 0,
                isLaunch: false
            }
        },
        watch: {},
        created() {
            transfer.$on("setMenuLaunchpad", () => {
                this.setMenuLaunchpad();
            });

            transfer.$on("onLaunchpadClaim", () => {
                this.onLaunchpadClaim();
            });

            transfer.$on("launchpadInitData", (data) => {
                this.launchpadInitData(data);
            });

            setTimeout(() => {
                this.onAccountsChanged();
            }, 1000);
        },
        mounted() {
            setTimeout(() => {
                this.initPageData();
            }, 500);

            window.onresize = () => {
                return (() => {
                    this.setBitseaPageHeight();
                })();
            }

            setTimeout(() => {
                this.setBitseaPageHeight();
            }, 10);
            setTimeout(() => {
                this.setBitseaPageHeight();
            }, 300);
        },
        destroyed() {
        },
        methods: {
            async onRefresh(accounts) {
                this.$store.commit('setState', {
                    accounts
                });

                await this.getCountPici();
                await this.getAllClaimNum();

                setTimeout(async () => {
                    await this.setBitseaPageHeight();
                }, 10);
            },
            async launchpadInitData(data) {
                await this.initPageData();

                setTimeout(async () => {
                    await this.setBitseaPageHeight();
                }, 500);
            },
            async initPageData() {
                await metaMaskTools.initParams().then(() => {});

                await this.getCountPici();
                await this.getAllClaimNum();

                setTimeout(async () => {
                    await this.setBitseaPageHeight();
                }, 10);
            },
            setBitseaPageHeight() {
                let divWidth = document.getElementById("bitsea_launchpad_page_02").clientWidth;
                let divHeight = document.getElementById("bitsea_launchpad_page_02").clientHeight;
                let divHeights = document.getElementById("bitsea_launchpad_page_02_divs").clientHeight;

                if (divHeights + 170 > divHeight) {
                    document.getElementById("bitsea_bottom_page").style.position = "relative";
                } else {
                    document.getElementById("bitsea_bottom_page").style.position = "absolute";
                }

                let bjActualWidth = ((divHeight / 1158) * 1920);
                if (divWidth < bjActualWidth) {
                    $('#bitsea_launchpad_page_bj').animate({ width : bjActualWidth }, 0);
                } else {
                    $('#bitsea_launchpad_page_bj').animate({ width : divWidth }, 0);
                }
            },
            setDecimal(numVal, numDecimalMax) {
                return toolUtils.setDecimal(numVal, numDecimalMax);
            },
            formatTimestamp(timestamp) {
                return toolUtils.formatTimestamp(timestamp);
            },
            setMenuLaunchpad() {
                this.returnMint();
            },
            async onMint(obj, index) {
                // return;

                this.nftPiciObj = obj;
                this.nftPiciObjIndex = parseInt(index);

                if (this.nftPiciObj.piciList.length > 0) {
                    let indexNft = 0
                    let indexTime = parseInt(new Date().getTime() / 1000);
                    for (let i = 0; i < this.nftPiciObj.piciList.length; i++ ) {
                        let piciObj = this.nftPiciObj.piciList[i];

                        if (indexTime < parseInt(this.nftPiciObj.piciList[0].startTime)) {
                            this.isEndOrStartCode = 1;
                            break;
                        }

                        if (indexTime > parseInt(this.nftPiciObj.piciList[this.nftPiciObj.piciList.length - 1].endTime)) {
                            this.isEndOrStartCode = this.nftPiciObj.piciList.length;
                            break;
                        }

                        if (indexTime > parseInt(piciObj.startTime) && indexTime <= parseInt(piciObj.endTime)) {
                            indexNft = parseInt(piciObj.pici);
                            this.nftPiciIndex = i;
                            break;
                        }
                    }
                    this.mintNftPici = indexNft;

                    this.isToMint = true;

                    if (this.nftPiciObj.address.toString().toUpperCase() === "0x5731F83cABB63D099d7D491E159EC59eF6Aa4469".toUpperCase()) {
                        await metaMaskTools.getMaxClaimNum(this.nftPiciObj.address, []).then(async (num) => {
                            this.maxClaimNum = num;
                        });

                        await this.getAllClaimNum();
                    }
                } else {
                    this.$message.warning('There are currently no batches that can be MINT!');
                }

                setTimeout(() => {
                    this.setBitseaPageHeight();
                }, 10);
                setTimeout(() => {
                    this.setBitseaPageHeight();
                }, 300);
            },
            async getAllClaimNum() {
                if (
                    this.$store.state.accounts.length > 0
                    &&
                    this.nftPiciObj
                    &&
                    this.nftPiciObj.address.toString().toUpperCase() === "0x5731F83cABB63D099d7D491E159EC59eF6Aa4469".toUpperCase()
                ) {
                    await metaMaskTools.getAllClaimNum(this.nftPiciObj.address, []).then(async (num) => {
                        this.userAllClaimNum = num
                    });
                }
            },
            returnMint() {
                this.isToMint = false;
                this.mintNftPici = 0;
                this.nftPiciIndex = -1;
                this.isEndOrStartCode = 0;
                this.nftPiciObjIndex = -1;
                this.nftPiciObj = null;
                this.getCountPici();

                setTimeout(() => {
                    this.setBitseaPageHeight();
                }, 10);
                setTimeout(() => {
                    this.setBitseaPageHeight();
                }, 300);
            },
            async onLaunchpadClaim(){
                this.isToMint = false;
                this.mintNftPici = 0;
                this.nftPiciIndex = -1;
                this.isEndOrStartCode = 0;
                this.nftPiciObjIndex = -1;
                this.nftPiciObj = null;
                await this.getCountPici();

                await setTimeout(async () => {
                    for (let i = 0; i < this.nftPiciList.length; i++) {
                        if (parseInt(this.$store.state.networkIDstring) === 1502 || parseInt(this.$store.state.networkIDstring) === 11501) {
                            if (this.nftPiciList[i].address.toString().toUpperCase() === "0x037b572661137537611F707CFA3F206994767e23".toUpperCase()) {
                                await this.onMint(this.nftPiciList[i], i);
                            }
                        } else if (parseInt(this.$store.state.networkIDstring) === 212 || parseInt(this.$store.state.networkIDstring) === 22776) {
                            if (this.nftPiciList[i].address.toString().toUpperCase() === "0x57C46671A01Aad9359D97F844916CCAb4bf6713c".toUpperCase()) {
                                await this.onMint(this.nftPiciList[i], i);
                            }
                        } else if (parseInt(this.$store.state.networkIDstring) === 223) {

                        }
                    }
                }, 10);

                await setTimeout(async () => {
                    await this.setBitseaPageHeight();
                }, 10);
                await setTimeout(async () => {
                    await this.setBitseaPageHeight();
                }, 300);
            },
            async toMint(address) {
                // this.$message.warning('Coming soon!');
                // return;

                if (address && this.nftPiciObj && this.nftPiciObj.piciList.length > 0) {

                    if (address.toString().toUpperCase() === "0x5731F83cABB63D099d7D491E159EC59eF6Aa4469".toUpperCase()) {
                        if (this.$store.state.accounts.length > 0) {
                            await metaMaskTools.getClaimNum(address, []).then(async (num) => {
                                if (num && parseInt(num) > 0) {
                                    let picis = [];
                                    for (let n = 0; n < parseInt(num); n++) {
                                        picis.push(1);
                                    }
                                    await metaMaskTools.nftclaim(address, [picis]).then(async (data) => {
                                        await this.getCountPici();
                                        await this.getAllClaimNum();

                                        await this.$http.upchainok().then((res) => {
                                            console.log(res);
                                        });
                                    });
                                } else {
                                    this.$message.warning('There is nothing to claim!');
                                }
                            });
                        } else {
                            this.$message.warning('Please connect the wallet!');
                        }
                    } else {
                        if (this.nftPiciObj.details.title === "MonsterLand404") {
                            let indexNft = 0;
                            let isUserMint = true;
                            let indexTime = parseInt(new Date().getTime() / 1000);

                            for (let i = 0; i < this.nftPiciObj.piciList.length; i++ ) {
                                let piciObj = this.nftPiciObj.piciList[i];
                                if (indexTime >= parseInt(piciObj.startTime) && indexTime < parseInt(piciObj.endTime)) {
                                    indexNft = parseInt(piciObj.pici);
                                    isUserMint = false;
                                    break;
                                }
                            }

                            if (isUserMint) {
                                this.$message.warning('There are currently no batches that can be MINT!');
                                return;
                            }

                            if (this.$store.state.accounts.length > 0) {
                                let isUserMint = true;
                                if (indexNft === 1) {
                                    await metaMaskTools.getMintWhiteList([this.$store.state.accounts[0]]).then(async (res) => {
                                        if (!res) {
                                            this.$message.warning('Not on the whitelist!');
                                            isUserMint = false;
                                        }
                                    });
                                }

                                if (isUserMint) {
                                    await metaMaskTools.userMint().then(async (data) => {
                                        await this.getCountPici();

                                        await this.$http.upchainok().then((res) => {
                                            console.log(res);
                                        });
                                    });
                                }
                            } else {
                                this.$message.warning('Please connect the wallet!');
                            }
                        } else {
                            let indexNft = 0;
                            let indexTime = parseInt(new Date().getTime() / 1000);

                            for (let i = 0; i < this.nftPiciObj.piciList.length; i++ ) {
                                let piciObj = this.nftPiciObj.piciList[i];
                                if (indexTime >= parseInt(piciObj.startTime) && indexTime < parseInt(piciObj.endTime)) {
                                    indexNft = parseInt(piciObj.pici);
                                    this.nftPiciIndex = i;
                                    break;
                                }
                            }
                            this.mintNftPici = indexNft;

                            if (this.nftPiciIndex === -1 || this.mintNftPici <= 0) {
                                this.$message.warning('There are currently no batches that can be MINT!');
                                return;
                            }
                            if (this.$store.state.accounts.length > 0) {
                                try {
                                    let userIsWhite = false;

                                    if (this.nftPiciObj.piciList[this.nftPiciIndex].whiteListOpen > 0) {
                                        await metaMaskTools.getIsWhiteList(address, [this.$store.state.accounts[0]]).then(async (isWhite) => {
                                            if (isWhite <= 0) {
                                                userIsWhite = false;
                                                this.$message.warning('Not on the whitelist!');
                                            } else {
                                                userIsWhite = true;
                                            }
                                        });
                                    } else {
                                        userIsWhite = true;
                                    }

                                    if (userIsWhite) {
                                        let price = BigNumberMul(this.nftPiciObj.piciList[this.nftPiciIndex].price, 1);

                                        await metaMaskTools.toNftMint(address, [this.mintNftPici], price).then(async (data) => {

                                            await this.getCountPici();

                                            await this.$http.upchainok().then((res) => {
                                                console.log(res);
                                            });
                                        });
                                    }
                                } catch (err) {
                                    console.log(err);
                                }
                            } else {
                                this.$message.warning('Please connect the wallet!');
                            }
                        }
                    }
                } else {
                    this.$message.warning('Invalid mint!');
                }
            },
            async getCountPici() {
                this.isLaunchpadLoading = true;

                try {
                    let nftPiciArr = [];

                    for (let x = 0; x < bitseaNfts.length; x++) {
                        if (bitseaNfts[x].name === "MonsterLand404") {
                            await metaMaskTools.getBalanceOf([monsterLandAddress]).then(async (res) => {
                                this.mstlBalanceOf = parseInt(parseInt(res) / 1e18);
                            });

                            await metaMaskTools.getMintPici().then(async (pici) => {
                                let piciArr = [];
                                let countAllNum = 5999;

                                if (pici) {
                                    for (let i = 1; i <= parseInt(pici); i++) {
                                        await metaMaskTools.getMintNftInfo([i]).then(async (res) => {
                                            if (res) {
                                                let piciObj = {
                                                    pici: i,
                                                    uri: "https://bafybeicp7gbeeg7sjlaafi2sivbgrntlaotyai6znidv5f2kx5tdqzhj4a.ipfs.nftstorage.link/MonsterLandLogo.png",
                                                    type : 0,
                                                    countNum : 5999,
                                                    mintNum : res[0],
                                                    startTime : res[1],
                                                    endTime : res[2],
                                                    price : 0,
                                                    open : 1,
                                                    whiteListOpen: res[3],
                                                };
                                                piciArr.push(piciObj);
                                            }
                                        })
                                    }
                                }

                                let obj = {
                                    address: bitseaNfts[x].address,
                                    piciList: piciArr,
                                    details: bitseaNfts[x].details,
                                    countAllNum: countAllNum,
                                    isLaunchpad: bitseaNfts[x].isLaunchpad,
                                };
                                nftPiciArr.push(obj);
                            });
                        } else if (!bitseaNfts[x].isLaunchpad) {
                            let obj = {
                                address: bitseaNfts[x].address,
                                piciList: [],
                                details: bitseaNfts[x].details,
                                countAllNum: 0,
                                isLaunchpad: bitseaNfts[x].isLaunchpad,
                            };
                            nftPiciArr.push(obj);
                        } else {
                            await metaMaskTools.getCountPici(bitseaNfts[x].address).then(async (pici) => {
                                let piciArr = [];
                                let countAllNum = 0;

                                if (pici) {
                                    for (let i = 1; i <= parseInt(pici); i++) {

                                        let syuCount = 0;
                                        if (i === parseInt(pici) && parseInt(pici) > 1) {
                                            await metaMaskTools.getCountNftNum(bitseaNfts[x].address).then(async (num) => {
                                                syuCount = parseInt(num);
                                            });
                                        }

                                        await metaMaskTools.getInfoPici(bitseaNfts[x].address, i.toString()).then(async (res) => {

                                            if (res) {
                                                let piciObj = {
                                                    pici: i,
                                                    uri: res[1],
                                                    type : res[0][0],
                                                    countNum : res[0][1],
                                                    mintNum : (i === parseInt(pici) && parseInt(pici) > 1) ? syuCount : res[0][2],
                                                    startTime : res[0][3],
                                                    endTime : res[0][4],
                                                    price : res[0][5],
                                                    open : res[0][6],
                                                    whiteListOpen: res[0][7],
                                                };
                                                piciArr.push(piciObj);

                                                countAllNum = countAllNum + parseInt(res[0][1]);
                                            }
                                        });
                                    }
                                }

                                let obj = {
                                    address: bitseaNfts[x].address,
                                    piciList: piciArr,
                                    details: bitseaNfts[x].details,
                                    countAllNum: countAllNum,
                                    isLaunchpad: bitseaNfts[x].isLaunchpad,
                                };
                                nftPiciArr.push(obj);
                            });
                        }
                    }

                    this.nftPiciList = nftPiciArr;

                    if (this.nftPiciObjIndex >= 0) {
                        this.nftPiciObj = this.nftPiciList[this.nftPiciObjIndex];
                    }
                } catch (err) {
                    console.log(err);
                }

                this.isLaunchpadLoading = false;

                setTimeout(async () => {
                    await this.getCountPici();
                }, 30000);
            },
            getPiCiAllCount(list) {
                let allCount = 0;
                for (let i = 0; i < list.length; i++) {
                    allCount = allCount + parseInt(list[i].countNum);
                }
                return allCount;
            }
        }
    }
</script>

<style lang="less">
    .bitsea_launchpad_page {
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        .bitsea_bj_img {
            position: absolute;
            width: 100%;
            height: calc(100% - 84px);
            top: 84px;
            bottom: 0;
            left: 0;
        }
        .bitsea_launchpad_page_01 {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        .bitsea_launchpad_page_02::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        .bitsea_launchpad_page_02 {
            overflow-y: auto;
            position: absolute;
            width: 100%;
            top: 84px;
            bottom: 0;
            .bitsea_launchpad_page_02_divs {
                position: relative;
                width: 80%;
                top: 0;
                bottom: 0;
                left: 10%;
                .bitsea_launchpad_page_021 {
                    .bitsea_launchpad_page_0211 {
                        margin: 100px 0 50px 0;
                    }
                    .bitsea_launchpad_page_0212 {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        justify-content: flex-start;
                        align-items: center;
                        margin-left: -10px;
                        margin-bottom: 70px;
                        .bitsea_launchpad_page_02121 {
                            position: relative;
                            margin: 10px;
                            cursor: pointer;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: stretch;

                            width: 450px;
                            height: 240px;
                            /*background: rgba(19,19,19,0.5);*/
                            border-radius: 16px 16px 16px 16px;
                            opacity: 1;
                            border: 1px solid #3A3A3C;
                            .bitsea_launchpad_page_021211_bj {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 450px;
                                height: 240px;

                                -webkit-filter: blur(64px);
                                filter: blur(64px);
                            }
                            .bitsea_launchpad_page_021211 {
                                width: 200px;
                                height: 200px;
                                position: absolute;
                                margin: 19px;

                                @media (max-width: 1023px){
                                    border-radius: 10px;
                                }
                            }
                            .bitsea_launchpad_page_021212 {
                                display: flex;
                                flex-direction: column;
                                flex-wrap: nowrap;
                                justify-content: space-between;
                                align-items: flex-start;
                                position: absolute;
                                left: 240px;
                                margin: 12px;
                                .bitsea_launchpad_page_021212_all {
                                    .bitsea_launchpad_page_0212121 {
                                        margin: 10px 0;
                                        /*height: 30px;*/
                                        font-size: 20px;
                                        // font-family: Inter, Inter;
                                        font-weight: bold;
                                        color: #F7931A;
                                        line-height: 30px;
                                        -webkit-background-clip: text;
                                    }
                                    .bitsea_launchpad_page_0212122 {
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        width: 172px;
                                        height: 130px;
                                        font-size: 12px;
                                        // // font-family: Inter, Inter;
                                        // font-weight: 500;
                                        color: rgba(255,255,255,0.3);
                                        line-height: 1.5;
                                        -webkit-background-clip: text;

                                        @media (max-width: 1023px){
                                            overflow-y: auto;
                                        }
                                    }
                                }
                                .bitsea_launchpad_page_0212123 {
                                    width: 100%;
                                    margin-top: 10px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: flex-end;
                                    align-items: center;
                                    .bitsea_launchpad_page_02121231 {
                                        margin-right: 10px;
                                    }
                                    .bitsea_launchpad_page_02121232 {
                                        margin-right: 10px;
                                    }
                                    .bitsea_launchpad_page_02121233 {
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }
                        .bitsea_launchpad_page_02122 {
                            width: 100%;
                            margin-top: 100px;
                            color: white;
                            text-align: center;
                            font-size: 24px;
                            font-weight: 600;
                        }
                    }
                }
                .bitsea_launchpad_page_022 {
                    margin-top: 100px;
                    margin-bottom: 70px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: stretch;

                    width: 100%;
                    border-radius: 16px 16px 16px 16px;
                    opacity: 1;
                    border: 1px solid #3A3A3C;

                    @media (max-width: 1023px){
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: stretch;
                        flex-wrap: nowrap;
                    }
                    .bitsea_launchpad_page_0221 {
                        width: 400px;
                        margin: 2%;
                        .bitsea_launchpad_page_02211 {
                            width: 100%;
                            @media (max-width: 1023px){
                                border-radius: 10px;
                            }
                        }

                        @media (max-width: 1023px){
                            width: 96%;
                        }
                    }
                    .bitsea_launchpad_page_0222 {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: stretch;

                        width: calc(100% - 400px - 8%);
                        margin: 2%;

                        @media (max-width: 1023px){
                            width: auto;
                        }
                        .bitsea_launchpad_page_02221 {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: center;
                            .bitsea_launchpad_page_022211 {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                justify-content: flex-start;
                                align-items: flex-end;
                                .bitsea_launchpad_page_0222111 {
                                    height: 33px;
                                    font-size: 30px;
                                    // font-family: Arial, Arial;
                                    font-weight: bold;
                                    color: #FFFFFF;
                                    line-height: 35px;
                                    -webkit-background-clip: text;

                                    @media (max-width: 1023px){
                                        width: min-content;
                                        height: auto;
                                    }
                                }
                                .bitsea_launchpad_page_0222112 {
                                    margin-left: 10px;
                                    width: 15px;
                                    margin-left: 15px;
                                    opacity: 0.5;
                                }
                                .bitsea_launchpad_page_0222112:hover{
                                    opacity: 1;
                                }
                            }
                            .bitsea_launchpad_page_022212 {
                                cursor: pointer;
                                opacity: 0.5;
                            }
                        }
                        .bitsea_launchpad_page_02222 {
                            margin: 50px 0;
                            font-size: 14px;
                            white-space: pre-wrap;
                            // // font-family: Inter, Inter;
                            // font-weight: 100;
                            color: rgba(255,255,255,0.2);
                            line-height: 1.5;
                            -webkit-background-clip: text;
                            text-align: justify;
                            @media (max-width: 1023px){
                                height: 80px;
                                overflow: hidden;
                            }
                        }
                        .bitsea_launchpad_page_02222_pc {
                            margin: 50px 0;
                            font-size: 14px;
                            white-space: pre-wrap;
                            color: rgba(255, 255, 255, 0.2);
                            line-height: 1.5;
                            -webkit-background-clip: text;
                            text-align: justify;
                        }
                        .bitsea_launchpad_page_02222_01 {
                            display: none;
                            text-align: center;
                            color: white;
                            cursor: pointer;
                            margin-top: -35px;
                            height: 50px;
                            @media (max-width: 1023px){
                                display: block;
                            }
                        }
                        .bitsea_launchpad_page_02223 {
                            border-top: 1px solid #393939;
                            margin-bottom: 5%;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: center;

                            @media (max-width: 1023px){
                                display: flex;
                                flex-direction: column;
                                flex-wrap: nowrap;
                                justify-content: flex-start;
                                align-items: center;
                                img {
                                    display: none;
                                }
                            }
                            .bitsea_launchpad_page_022231 {
                                .bitsea_launchpad_page_0222311 {
                                    margin-top: 15px;
                                    height: 36px;
                                    font-size: 24px;
                                    // font-family: Inter, Inter;
                                    font-weight: bold;
                                    color: #F4982B;
                                    line-height: 36px;
                                    -webkit-background-clip: text;
                                }
                                .bitsea_launchpad_page_0222312 {
                                    height: 27px;
                                    font-size: 18px;
                                    // font-family: Inter, Inter;
                                    font-weight: 500;
                                    color: #FFFFFF;
                                    line-height: 27px;
                                    -webkit-background-clip: text;
                                    text-align: center;
                                    margin-top: 15px;
                                }
                            }
                            .bitsea_launchpad_page_022232 {
                                .bitsea_launchpad_page_0222321 {
                                    margin-top: 15px;
                                    height: 36px;
                                    font-size: 24px;
                                    // font-family: Inter, Inter;
                                    font-weight: bold;
                                    color: #F4982B;
                                    line-height: 36px;
                                    -webkit-background-clip: text;
                                    text-align: center;
                                }
                                .bitsea_launchpad_page_0222322 {
                                    height: 27px;
                                    font-size: 18px;
                                    // font-family: Inter, Inter;
                                    font-weight: 500;
                                    color: #FFFFFF;
                                    line-height: 27px;
                                    -webkit-background-clip: text;
                                    text-align: center;
                                    margin-top: 15px;
                                }
                            }
                            .bitsea_launchpad_page_022233 {
                                .bitsea_launchpad_page_0222331 {
                                    margin-top: 15px;
                                    height: 36px;
                                    font-size: 24px;
                                    // font-family: Inter, Inter;
                                    font-weight: bold;
                                    color: #F4982B;
                                    line-height: 36px;
                                    -webkit-background-clip: text;
                                }
                                .bitsea_launchpad_page_0222332 {
                                    height: 27px;
                                    font-size: 18px;
                                    // font-family: Inter, Inter;
                                    font-weight: 500;
                                    color: #FFFFFF;
                                    line-height: 27px;
                                    -webkit-background-clip: text;
                                    text-align: center;
                                    margin-top: 15px;
                                }
                            }
                        }
                        .bitsea_launchpad_page_02224 {
                            width: 100%;
                            height: 55px;
                            line-height: 55px;
                            color: black;
                            font-size: 20px;
                            // font-family: Inter, Inter;
                            font-weight: bold;
                            text-align: center;
                            background-color: #f8af55;
                            background-image: linear-gradient(to right, #f8af55, #F7931A);
                            border-radius: 25px;
                            cursor: pointer;
                            margin-bottom: 3%;
                        }
                        .bitsea_launchpad_page_02225 {
                            color: white;
                            font-size: 14px;
                            text-align: center;
                            margin-top: -1%;
                        }
                    }
                    .bitsea_launchpad_page_0223 {
                    }
                }
            }
            .bitsea_launchpad_page_03 {
                width: 100%;
            }
        }
    }

    .el-carousel__indicators--outside button {
        background-color: #F7931A !important;
    }
    .el-carousel__indicators--outside {
        margin-top: 8% !important;
    }
    .el-carousel__container {
        height: 400px;
    }
</style>
